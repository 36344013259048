export const database = {obtenerCamara};

async function obtenerCamara(centro) {

    //centro = CETELEM
    console.log("centro", centro)

    //var ruta = 'https://fichasapi.eurest.es/api/get-aforo-centro';
    var ruta = process.env.REACT_APP_NODEDB;
    var datos = { centro };

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
        body: JSON.stringify(datos)
    };

    return fetch(ruta, requestOptions)
        .then(response => response.json().then(response => ({ response })))
        .then(data => { return data })
        .catch(error => console.error('Error:' + error));
}