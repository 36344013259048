import React, { useState, useEffect, useCallback } from 'react'
import queryString from 'query-string'
import { useParams, useLocation } from 'react-router-dom'
import {format} from 'date-fns'
import 'bootstrap/dist/css/bootstrap.min.css';
import { es } from 'date-fns/esm/locale'
import {database} from '../service'
import '../styles/Aforo2.css';
import logo from '../assets/images/logo.png';
import icon1 from '../assets/images/name.svg';
import icon2 from '../assets/images/eat.svg';
import icon3 from '../assets/images/percentage.svg';
import percentage1 from '../assets/images/percentage1.svg';
import percentage2 from '../assets/images/percentage2.svg';
import percentage3 from '../assets/images/percentage3.svg';
import percentage4 from '../assets/images/percentage4.svg';
import info from '../assets/images/info.svg';

function Aforo2(){    
    //#region Variables
    //Parametros de ruta
    const {id} = useParams();
    const { search } = useLocation();
    const values = queryString.parse(search)
    //Variables para ocultar aforo y gráfico
    const [showAforo, setShowAforo] = useState(null)
    const [showGraphic, setShowGraphic] = useState(null)
    //Variables configurables una vez
    const [iniciado, setIniciado] = useState(false);  
    const [maxAforo, setMaxAforo] = useState(1);
    const [idCamara, setIdCamara] = useState("");
    const [pc1, setPc1] = useState(25);
    const [pc2, setPc2] = useState(50);
    const [pc3, setPc3] = useState(75);

    //Variables que actualizan el estado.
    const [estado, setEstado] = useState('ERROR');
    const [aforo, setAforo] = useState(0);
    const [prcAforo, setPrcAforo] = useState((aforo*100)/maxAforo);  
    
    //Hora actual
    const [time, setTime] = useState('')
    // Reescalado de pantalla
    const [dimensions, setDimensions] = useState({ 
        height: window.innerHeight,
        width: window.innerWidth
    })
    const [circleSize, setCircleSize] = useState(dimensions.height > dimensions.width ? Math.round((dimensions.width*0.50)/2) : Math.round((dimensions.height*0.50/2)) )
    
    const handleResize = useCallback(() =>{
        setDimensions({
            height: window.innerHeight,
            width: window.innerWidth
 
        })
    },[])

    window.addEventListener('resize', handleResize)

   //#endregion
   
   //#region Metodos
   //Busca los datos de configuración de la cámara que le pasas por parámetro. MaxAforo, IdCamara.
    const inicializar = () =>{
        const configData = process.env.REACT_APP_CAMCONFIG;   

        console.log(window.innerWidth);
        
        database.obtenerCamara(id)
        .then((res) => {
            if(res.response.status=="success"){
                if(res.response.message.length!=0){
                    setMaxAforo(res.response.message[0].maxAforo);                
                    setIdCamara(res.response.message[0].idCamara);
                    setPc1(res.response.message[0].pC1);
                    setPc2(res.response.message[0].pC2);
                    setPc3(res.response.message[0].pC3);

                    setIniciado(true);
                }
            }
        });
        
        /*
        const cfg = JSON.parse(configData);
        cfg.data.map(data =>{
            if(data.id==id){
                setMaxAforo(data.maxAforo);                
                setIdCamara(data.idCamara);
                setPc1(data.PC1);
                setPc2(data.PC2);
                setPc3(data.PC3);

                setIniciado(true);
            }         
        })  
        */
    }
    //obtiene los datos actuales y el estado que capta por la cámara. Estado, Aforo actual, y porcentaje de llenado.
    const actualizar = () =>{
        var url = process.env.REACT_APP_CAM_URL + idCamara

        fetch(url)
        .then(response => response.json())
        .then(json => {
            console.log(json);
            setEstado('' + json.status);

            if(json.status === 'OK'){
                setAforo(0 + json.counter);     
                setPrcAforo(Math.round((json.counter*100)/maxAforo)); 
                setTime(format(new Date(), 'H:mm')) 
            }            
            setTimeout(actualizar, 120000);
            //setTimeout(actualizar, 5000);
        })
        .catch(function(error) {
            setEstado("ERROR");
            setTimeout(actualizar, 120000);
            //setTimeout(actualizar, 5000);
        });
    }
    //#endregion

    useEffect(() =>{
        if(iniciado){
            actualizar();
        }else{
            inicializar();
        }
    },[iniciado])

    useEffect(() =>{
        if(values.a === undefined || values.a === 'true'){
            setShowAforo(true)
        }else{
            setShowAforo(false)
        }

        if(values.g === undefined || values.g === 'true'){
            setShowGraphic(true)
        }else{
            setShowGraphic(false)
        }
    }, [values.a, values.g])

    useEffect(() =>{
        setCircleSize(dimensions.height > dimensions.width ? Math.round((dimensions.width*0.50)/2) : Math.round((dimensions.height*0.50/2)) )
    }, [dimensions])

    //[maxAforo, iniciado, pc1, pc2, pc3, idCamara]

    //#region Configuracion de colores

    const colorAforo = () =>{
        let estilo = '';

        if(prcAforo<pc1){
            estilo = "color-progress-1"
        }else if(prcAforo>=pc1 && prcAforo<pc2){
            estilo = "color-progress-2";
        }else if(prcAforo>=pc2 && prcAforo<pc3){
            estilo = "color-progress-3";
        }else{
            estilo = "color-progress-4";
        }

        return estilo;
    }

    const colorPercentage = () =>{
        let estilo = '';

        if(prcAforo<pc1){
            estilo = "color-percentage-1"
        }else if(prcAforo>=pc1 && prcAforo<pc2){
            estilo = "color-percentage-2";
        }else if(prcAforo>=pc2 && prcAforo<pc3){
            estilo = "color-percentage-3";
        }else{
            estilo = "color-percentage-4";
        }

        return estilo;
    }

    const getIconByProgress = () =>{
        let icon = '';

        if(prcAforo<pc1){
            icon = percentage1
        }else if(prcAforo>=pc1 && prcAforo<pc2){
            icon = percentage2;
        }else if(prcAforo>=pc2 && prcAforo<pc3){
            icon = percentage3;
        }else{
            icon = percentage4;
        }

        return icon;
    }

    const colorConexion = () =>{
        if(estado ==='OK'){
            return 'date border-success'
        }else{
            return 'date border-danger'
        }
    }
    const porcentajeProgress = () =>{
        var porcentaje = 0
        var circleLong = Math.round(Math.PI*circleSize*2);

        if (prcAforo>100){
            porcentaje = 0
        }else{
            porcentaje = (circleLong - (circleLong * prcAforo / 100))
        }

        return porcentaje;
    }    

    const getDate = useCallback(() =>{
        const date = format(new Date(), 'iiii dd/MM/yy',{ locale: es });
        return date
    }, [])
    
    //#endregion

    //#region Vista principal
    return (        
        <div className='fill-window'>                     
            <div className="header">
                <span><img src={info} alt="info"/>{estado ==='OK' ? <span>Información de aforo en tiempo real</span> : <span>Sin conexión - Mostrando última actualización</span>}</span>                            
            </div>
            <div className='container-center'>
                <div className={colorConexion()}>
                    <p>{getDate()}</p>
                    <p>{time}</p>
                </div>
                <div className="card">
                    <div className="card-left">
                        <div className="card-left-row">
                            <img src={icon1} alt=""/><p>{idCamara}</p>
                        </div>
                        <div className="card-left-row">
                            <img src={icon2} alt=""/><p>Aforo: {maxAforo ? maxAforo : '-'}</p>
                        </div>
                        {showAforo &&(
                        <>
                            <div className="card-left-row">
                                <img src={icon3} alt=""/><p>Ocupación: {aforo ? aforo : '-'}</p>
                            </div>
                        </>
                        )}
                    </div>
                    <div className="card-right">
                        <div className='box'>
                            {showGraphic &&(
                                <div className='percent'>
                                    <svg width={circleSize*2 + 40} height={circleSize*2 + 40}>
                                        <circle cx={circleSize} cy={circleSize} r={circleSize}></circle>
                                        <circle cx={circleSize} cy={circleSize} r={circleSize} className={colorAforo()} style={{strokeDashoffset:porcentajeProgress(), strokeDasharray:Math.round(Math.PI*circleSize*2)}} ></circle>
                                    </svg>
                                    <div className='number'>
                                        <img src={getIconByProgress()} alt="group"/>
                                        <h2 className={colorPercentage()}>{prcAforo}<span className={colorPercentage()}>%</span></h2>
                                    </div>
                                </div>                            
                            )}
                        </div>
                    </div>
                </div>            
            </div>  
            <div className="footer">
                <span><img src={info} alt="info"/>{estado=='OK' ? <span>Información de aforo en tiempo real</span> : <span>Sin conexión - Mostrando última actualización</span>}</span>  
                <img src={logo} alt="logo-compass" />                         
            </div>         
        </div>
    )
    //#endregion
}

export default Aforo2