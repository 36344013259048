import {
  BrowserRouter as Router,
  Route,
  Switch
} from 'react-router-dom';
import Inicio from './components/Inicio';
import Aforo from './components/Aforo';
import Aforo2 from './components/Aforo2';

function App() {
  return (
    <Router>
      <Switch>        
        <Route path="/Aforo/:id" children={<Aforo2/>}></Route>
      </Switch>
    </Router>
  );
}

export default App;